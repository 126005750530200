import React, {useState} from 'react';
import Video from '../../videos/video.mp4' ;
import { Button } from '../ButtonElement';
import { HeroContainer, HeroBg, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight } from './HeroElements';
import Typed from "react-typed";

export const HeroSection = () => {
    const [hover, setHover ] = useState(false);

    const onHover = () => {
        setHover(!hover)
    }
  return (
    <HeroContainer id="home">
        <HeroBg>
            <VideoBg autoPlay loop muted src={Video} type='video/mp4' />
        </HeroBg>
        <HeroContent>
            <HeroH1> 
                <div className="animated-typing">
                    <Typed
                    strings={[
                        "Softwaresolution",
                        "IT is a Never Ending Story"
                    ]}
                    typeSpeed={50}
                    backSpeed={35}
                    loop
                    />
                </div>
            </HeroH1>
            <HeroP>
                 {/*Ralph-Peter Zillbauer*/}
            </HeroP>
            <HeroBtnWrapper>
                <Button to="contactme" onMouseEnter={onHover} onMouseLeave={onHover}
                primary="true"
                dark="true"
                >Contact us {hover ? <ArrowForward /> : <ArrowRight />}</Button>
            </HeroBtnWrapper>
        </HeroContent>
    </HeroContainer>
  )
};

