export const homeObjOne = {
    id: 'about', 
    lightBg: false,
    lightText : true,
    lightTextDesc: true,
    topLine: '',
    headline: 'IT Dienstleistungen',
    description: 'Sie wollen Ihre Prozesse automatisieren? Wir können Ihnen dabei helfen.',
    buttonLabel: 'Home',
    imgStart: false,
    img: require('../../images/iStock-1202843504.jpg'),
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false,
    linkto: 'home'
}

export const homeObjTwo = {
    id: 'discover', 
    lightBg: true,
    lightText : false,
    lightTextDesc: false,
    topLine: 'Entwicklung',
    headline: 'Mehrjährige Erfahrung im Entwicklungsbereich von M365 & Azure',
    description: '',
    buttonLabel: 'Home',
    imgStart: true,
    img: require('../../images/softsollogo.jpg'),
    alt: 'SoftSolLogo',
    dark: false,
    primary: false,
    darkText: true,
    linkto: 'home'
}

export const homeObjThree = {
    id: 'services', 
    lightBg: false,
    lightText : true,
    lightTextDesc: true,
    topLine: 'IT Beratung',
    headline: 'Umstieg in die Cloud?',
    description: 'Sie möchten Ihre Infrastruktur in die Cloud auslagern? ',
    buttonLabel: 'Home',
    imgStart: false,
    img: require('../../images/iStock-1530256492.jpg'),
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false,
    linkto: 'home'
}
