import React from 'react';
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
} from './ServicesElements'
import Icon1 from '../../images/iStock-1341880394.jpg';
import Icon2 from '../../images/iStock-1135472464.jpeg';
import Icon3 from '../../images/iStock-1530256492.jpg';
import Icon4 from '../../images/iStock-1474465082.jpg';
import Icon5 from '../../images/iStock-1689203717.jpg';
import Icon6 from '../../images/Magenta_BusinessPartner.png';




const Services = () => {
  
  return (
    <ServicesContainer id="services">
      <ServicesH1>Dienstleistungen</ServicesH1>
      <ServicesWrapper>
        <ServicesCard>
          <ServicesIcon src={Icon1} />
          <ServicesH2>Infrastruktur Optimierung</ServicesH2>
          <ServicesP>Wir unterstützen Sie dabei, Ihre Infrastruktur auf den neuesten Stand zu bringen. Zudem bieten wir eine Vielzahl von Services an, einschließlich der Möglichkeit eines Managed Services.</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon2} />
          <ServicesH2>Telefonanlagen</ServicesH2>
          <ServicesP>Verwenden Sie noch eine ISDN-Telefonanlage und möchten auf eine Cloud-basierte Telefonie umsteigen? Profitieren Sie von sämtlichen Cloud-Funktionen und nutzen Sie Ihre Anlage bequem von jedem Standort aus.</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon3} />
          <ServicesH2>Eigenentwicklung</ServicesH2>
          <ServicesP>Haben Sie spezielle Anforderungen, wie beispielsweise die Automatisierung von Prozessen oder die Integration von Logiken zwischen verschiedenen Systemen? Dank modernster Programmiersprachen können wir Ihre Wünsche umsetzen.</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon4} />
          <ServicesH2>Videoüberwaachung</ServicesH2>
          <ServicesP>Möchten Sie Ihre Sicherheit erhöhen und Videoüberwachungssysteme implementieren? Wir können Ihnen dabei helfen, maßgeschneiderte Lösungen für die Videoüberwachung zu entwickeln, die sich nahtlos in Ihre bestehende Infrastruktur integrieren lassen.
          </ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon5} />
          <ServicesH2>Security</ServicesH2>
          <ServicesP>Möchten Sie Ihre Netzwerksicherheit verbessern und eine leistungsstarke Firewall-Lösung implementieren? Wir unterstützen Sie dabei, eine maßgeschneiderte Firewall zu entwickeln, die optimal auf Ihre Anforderungen abgestimmt ist und Ihr Netzwerk effektiv schützt.</ServicesP>
        </ServicesCard>
        <ServicesCard>
          <ServicesIcon src={Icon6} />
          <ServicesH2>Magenta Partner</ServicesH2>
          <ServicesP>Wir sind stolzer Partner von Magenta und können Ihnen bei der Bereitstellung von Internetdiensten sowie sämtlichen anderen Services helfen.</ServicesP>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
